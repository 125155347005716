import React from "react";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import Heading from "../../commons/Heading";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import { linkResolver } from "../../../utils/links";
import { gradientBgVertical } from "../../../utils/styles";

export default function GridCardBlock({
  _rawTitle,
  cards,
  buttons,
  ...otherProps
}) {
  return (
    <GridRow
      as="section"
      className="bg-circle"
      sx={{
        backgroundColor: "background",
        py: [5, "gridGap"],
        textAlign: ["center", "start"],
      }}
    >
      <GridRow.Col gridColumn={["start / end", "start / span 5"]}>
        <RichTextRenderer blocks={_rawTitle} />
        {!!buttons.length && (
          <Box sx={{ mt: 4 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} variant="gradient" {...link} />
            ))}
          </Box>
        )}
      </GridRow.Col>
      <GridRow.Col gridColumn={["start / end", "7 / end"]} sx={{ mt: [4, 0] }}>
        <GridRow
          subGrid
          variant="half"
          sx={{
            position: "relative",
            zIndex: 0,
            py: 4,
            gridAutoRows: ["auto", "1fr"],
          }}
        >
          {cards.map((card, i) => (
            <Box
              key={card._key}
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: ["left", null, i % 2 ? "left" : "right"],
                alignItems: [
                  "flex-start",
                  null,
                  i % 2 ? "flex-start" : "flex-end",
                ],
                ml: ["15vw", null, 0],
                "&:first-child": {
                  "&:after": {
                    position: "absolute",
                    left: ["5vw", null, "50%"],
                    content: '" "',
                    height: "100%",
                    width: "6px",
                    ml: "-3px",
                    top: 0,
                    transform: "rotate(0.5turn)",
                    ...gradientBgVertical,
                  },
                },
              }}
            >
              <Heading as="h3">{card.title}</Heading>
              <Box
                sx={{
                  mt: 3,
                  maxWidth: 400,
                }}
              >
                <RichTextRenderer blocks={card._rawDescription} />
              </Box>
            </Box>
          ))}
        </GridRow>
      </GridRow.Col>
    </GridRow>
  );
}
